<template>
  <div class="k-9sptci" v-loading="loading">
   
    <el-form
      :inline="true"
      class="k-9sptci-header"
      :model="searchForm"
      ref="searchForm"
    >
     <!-- 查询，新增赛事 -->
      <el-form-item>
        <el-button type="success" @click="searchClick">查询</el-button>
      </el-form-item>
      <perContainer :perm="`match:save`">
        <el-form-item>
          <el-button type="primary" @click="add">新增赛事</el-button>
        </el-form-item>
      </perContainer>
    </el-form>
    <el-table :data="dataTable" style="margin-top:20px" border>
      <!-- 表头 赛事，状态-->
      <template v-for="item in dataList">
        <el-table-column
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
        </el-table-column>
      </template>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <el-button @click.native.stop="handleEdit(scope.row)"
            >编辑赛事</el-button
          >
          <!-- 不设置权限了，用不到暂时 -->
          <el-button @click.native.stop="handleLeg(scope.row)"
            >赛段管理</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="k-9sptci-footer">
      <el-pagination
        @current-change="handleQuery"
        @size-change="handleSizeChange"
        :page-sizes="[10, 15, 20, 30, 50, 100]"
        :page-size="pageSize"
        :current-page.sync="pager.pageNum"
        layout="sizes, prev, pager, next"
        :total="pager.total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
// import perButton from "@/components/perm/perButton";
export default {
  name: "super_list_wai",
  components: {
    // perButton,
  },
  computed: {
    ...mapState("match", ["match_types"]),
  },

  data() {
    return {
      matchType: "",
      ids: [],
      type_id: "wdl",
      loading: false,
      pageSize: 15,

      qrcodeVisible: false,
      qrcodeData: {
        match_id: "",
        expired: false,
        src: "",
      },
      pager: {
        total: 0,
        pageNum: 1,
      },
      searchForm: {
        year: "",
        status: 0,
      },
      dataTable: [],
      dataList: [
        {
          prop: "match_name",
          label: "赛事",
          width: 300,
        },
        {
          prop: "match_time",
          label: "状态",
          width: 200,
          formatter: (row) => {
            let curentTime = new Date();
            //联赛赛事阶段
            if (curentTime < new Date(row.startTime)) {
              return "未开始";
            } else if (curentTime < new Date(row.endTime)) {
              return "进行中";
            } else {
              return "已结束";
            }
          },
        },
      ],
    };
  },
  methods: {
    add() {
      this.removeTabByPath(`/match/subject/managerLeague`);
      this.$router.push({
        path: `/match/subject/managerLeague`,
        query: {
          view_type: "add",
        },
      });
    },
    //赛段管理
    handleLeg(row) {
      this.removeTabByPath(`/match/subject/list`);
      this.$router.push({
        path: `/match/subject/list`,
        query: { match_id: row._id, match_type: row.match_type  },
      });
    },
    // 编辑赛事
    async handleEdit(row) {
      this.removeTabByPath(`/match/subject/managerLeague`);
      this.$router.push({
        path: `/match/subject/managerLeague`,
        query: {
          _id: row._id,
          view_type: "edit",
          match_type: row.match_type,
        },
      });
    },
   //查询
    searchClick() {
      this.$refs.searchForm.validate((v) => {});
      this.pager.pageNum = 1;
      this.handleQuery();                  
    },
    async handleSizeChange(val) {
      this.pageSize = val;
      this.pager.pageNum = 1;
      this.pager.total = 0;
      this.handleQuery();
    },
    //重置回收站的初始页码
    handleDepot() {
      this.pager.pageNum = 1;
      this.handleQuery();
    },
    async handleQuery() {
      const { pageNum } = this.pager;
      const { data } = await this.$http.match_subject.match_list({
        pageNum,
        pageSize: this.pageSize,
      });

      this.dataTable = data.data;
      this.pager = data.pager;
    },
    beforeTopImageUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }

      return isJPG && isLt2M;
    },

    onMatchChanged(data) {
      let current = this.dataTable.find((r) => r._id == data._id);
      if (current) {
        current.match_time = data.match_time;
        current.status = data.status;
      } else {
        this.pager.pageNum = 1;
        this.handleQuery();
      }
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  async created() {
    this.$store.commit("tabs/setVNode", this.$vnode);
    this.handleQuery();
    this.$bus.$off("onMatchChanged", this.onMatchChanged);
    this.$bus.$on("onMatchChanged", this.onMatchChanged);
  },
  destroyed() {
    this.$bus.$off("onMatchChanged", this.onMatchChanged);
  },
};
</script>
<style lang="less" scoped>
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 300px !important;
  height: 300px !important;
  line-height: 300px !important;
  text-align: center;
}
/deep/ .el-upload-dragger {
  width: 300px !important;
  height: 300px !important;
}
.avatar {
  width: 300px !important;
  height: 300px !important;
  display: block;
}

/deep/ .el-upload-dragger {
  text-align: center;
  width: 300px !important;
  height: 300px !important;
}

/deep/ .el-upload-dragger img {
  margin: auto;
}
</style>
